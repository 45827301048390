<dx-popup
  title="Edition du plan de production"
  [showTitle]="true"
  [height]="'auto'"
  [width]="'92wh'"
  (onHiding)="closeDialog()"
  [(visible)]="displayDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <strong><i class="fas fa-calendar mg-r-5"></i>SÉLECTION DES JOURS</strong>
          </div>
          <div class="card-body">
            <!--SELECTION TYPE JOUR-->
            <div class="row">
              <div class="col">
                <dx-button-group
                  [items]="typesJours"
                  keyExpr="value"
                  stylingMode="outlined"
                  [selectedItemKeys]="['jourFab']"
                  (onItemClick)="changeSelectedJour($event)"
                >
                </dx-button-group>
              </div>
            </div>
            <!--SELECTION JOURS-->
            <div class="row mg-t-10">
              <div class="col">
                <ng-container *ngTemplateOutlet="tplJours;context:{form:form}"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <strong><i class="fas fa-users mg-r-5"></i>SÉLECTION DES ÉQUIPES</strong>
          </div>
          <div class="card-body">
            <!--SELECTION ÉQUIPE-->
            <div class="row">
              <div class="col">
                <ng-container *ngTemplateOutlet="tplEquipes;context:{form:form}"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <strong><i class="fas fa-carrot mg-r-5"></i>SÉLECTION DES PLATS</strong>
          </div>
          <div class="card-body">
            <ng-container *ngTemplateOutlet="tplSelectPlats;context:{form:form}"></ng-container>
          </div>
        </div>
      </div>
    </div>

    <div id="group-lines" class="d-flex mg-t-10">
      <div class="card group-lines">
        <div class="card-header">
          <strong><i class="fa fa-object-group mg-r-5"></i>REGROUPEMENT DES LIGNES</strong>
        </div>
        <div class="card-body group-lines-body">
          <div class="mg-r-5">
            <label *ngIf="warningGroupingLines" class="danger-color">{{ warningGroupingLines }}</label>
            <ng-container *ngTemplateOutlet="tplDetail;context:{form:form}"></ng-container>
          </div>
        </div>
      </div>
    </div>

    <div id="cta-print-settings" class="d-flex mg-t-10">
      <div class="mr-auto"></div>
      <div class="mg-r-5">
        <yo-button
          iconClass="fas fa-print"
          (onClick)="printPlanProd()"
          label="Plan de production"
          [disabled]="!canPrint()"
        >
        </yo-button>
      </div>
      <div class="mg-r-5">
        <yo-button
          iconClass="fas fa-print"
          (onClick)="printFichesTechniquesClassique(orientationPortrait)"
          [disabled]="!canPrint()"
          label="Fiches techniques classiques (Portrait)">
        </yo-button>
      </div>
      <div class="mg-r-5">
        <yo-button
          iconClass="fas fa-print"
          (onClick)="printFichesTechniquesClassique(orientationPaysage)"
          [disabled]="!canPrint()"
          label="Fiches techniques classiques (Paysage)">
        </yo-button>
      </div>
      <div class="mg-r-5">
        <yo-button
          iconClass="fas fa-print"
          (onClick)="printFichesTechniquesCondensee(orientationPortrait)"
          [disabled]="!canPrint()"
          label="Fiches techniques condensées (Portrait)">
        </yo-button>
      </div>
      <div class="mg-r-5">
        <yo-button
          iconClass="fas fa-print"
          (onClick)="printFichesTechniquesCondensee(orientationPaysage)"
          [disabled]="!canPrint()"
          label="Fiches techniques condensées (Paysage)">
        </yo-button>
      </div>
    </div>


  </form>
</dx-popup>

<ng-template #tplSelectPlats let-form="form">
  <div *ngIf="datasourceProducts.length !== 0" [formGroup]="form">
    <dx-list
      #list
      [dataSource]="datasourceProducts"
      width="auto"
      [height]="330"
      selectionMode="all"
      selectAllMode="allPages"
      displayExpr="libelle"
      valueExpr="id"
      [showSelectionControls]="true"
      [searchEnabled]="true"
      searchExpr="libelle"
      searchMode="contains"
    ></dx-list>
  </div>
  <div *ngIf="!datasourceProducts.length">
    <p>Veuillez préselectionner une ou plusieurs equipe(s).</p>
  </div>
</ng-template>

<!--TEMPLATE SELECTION ÉQUIPES-->
<ng-template #tplEquipes let-form="form">
  <div *ngIf="_equipesItems.length > 0" [formGroup]="form">
    <dx-tree-list
      height="300"
      id="parent-grid"
      [dataSource]="_equipesItems"
      itemsExpr="children"
      dataStructure="tree"
      [showRowLines]="true"
      [showBorders]="true"
      [columnAutoWidth]="true"
      [expandedRowKeys]="[1]"
      (onSelectionChanged)="updateEquipes($event)"

    >
      <div class="test">
        <dxo-search-panel [visible]="true" width="inherit"></dxo-search-panel>
      </div>

      <dxo-selection
        mode="multiple"
        [recursive]="true">
      </dxo-selection>
      <dxi-column cssClass="search-label" dataField="label" caption="Sélectionner tout"></dxi-column>
      <dxo-scrolling mode="standard"></dxo-scrolling>

    </dx-tree-list>
  </div>
  <div *ngIf="!_equipesItems?.length">
    <p>Veuillez préselectionner une ou plusieurs date(s).</p>
  </div>
</ng-template>

<ng-template #tplDetail let-form="form">
  <div [formGroup]="form">
    <label>Détail des lignes par : </label>
    <dx-check-box text="Equipe" formControlName="groupByTeam"></dx-check-box>
    <span class="mg-r-5"></span>
    <dx-check-box text="Point de livraison client" formControlName="groupByPlc"></dx-check-box>
    <span class="mg-r-5"></span>
    <dx-check-box text="Repas" formControlName="groupByMeal"></dx-check-box>
    <span class="mg-r-5"></span>
    <dx-check-box text="Date de consommation" formControlName="groupByConsumptionDate"></dx-check-box>
  </div>
</ng-template>

<!--TEMPLATE SELECTION JOURS-->
<ng-template #tplJours let-form="form">

  <div [formGroup]="form">

    <!--quand jour fab sélectionné-->
    <ng-container *ngIf="typeJourSelected ==='jourFab'">
      <dx-list
        #listDatesFab
        [dataSource]="datasourceDatesFab"
        width="auto"
        [height]="330"
        selectionMode="all"
        selectAllMode="allPages"
        displayExpr="label"
        valueExpr="value"
        [showSelectionControls]="true"
        [searchEnabled]="true"
        (onSelectionChanged)="changeJourObligatoire()"
        searchExpr="label"
        searchMode="contains"
      ></dx-list>
    </ng-container>

    <!--quand jour conso sélectionné-->
    <ng-container *ngIf="typeJourSelected ==='jourConso'">
      <dx-list
        #listDatesConso
        [dataSource]="datasourceDatesConso"
        width="auto"
        [height]="330"
        selectionMode="all"
        selectAllMode="allPages"
        displayExpr="label"
        valueExpr="value"
        [showSelectionControls]="true"
        [searchEnabled]="true"
        (onSelectionChanged)="changeJourObligatoire()"
        searchExpr="label"
        searchMode="contains"
      ></dx-list>
    </ng-container>

    <ng-container *ngIf="!utils.isNullOrEmpty(jourObligatoire)">
      <span class="danger-color" [innerHTML]="domSvc.bypassSecurityTrustHtml(jourObligatoire)"></span>
    </ng-container>

  </div>

</ng-template>
