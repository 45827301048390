<dx-popup
  title="Edition des sorties de production"
  [showTitle]="true"
  [height]="'auto'"
  [width]="'92wh'"
  (onHiding)="closeDialog()"
  [(visible)]="displayDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <form [formGroup]="form">
    <div>
      <strong><i class="fa fa-object-group mg-r-5"></i>OPTIONS D'IMPRESSION</strong>
      <div class="mg-r-5">
        <div [formGroup]="form">
          <div class="m-3">
            <dx-check-box text="Numéro de lot" formControlName="numeroLot"></dx-check-box>
          </div>
          <div class="m-3">
            <dx-check-box text="DLC" formControlName="dlc"></dx-check-box>
          </div>
          <div class="m-3">
            <dx-check-box text="Quantité totale en US" formControlName="qteTotalUS"></dx-check-box>
          </div>
          <div class="m-3">
            <dx-check-box text="Texte libre" formControlName="commentaire"></dx-check-box>
          </div>
        </div>
      </div>
    </div>

    <div id="cta-print-settings" class="d-flex mt-3">
      <div class="mr-auto"></div>
      <div class="mg-r-5">
        <yo-button
          iconClass="far fa-file-pdf"
          (onClick)="printVueAtelier()"
          label="Vue par Atelier"
        >
        </yo-button>
      </div>
      <div class="mg-r-5">
        <yo-button
          iconClass="far fa-file-pdf"
          (onClick)="printVueZoneStockage()"
          label="Vue par zone de stockage">
        </yo-button>
      </div>
    </div>

  </form>
</dx-popup>
