// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]6.0-10296(3c571edf26)-C17/06/2024-15:52:11-B17/06/2024-16:29:45' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]6.0-10296(3c571edf26)-C17/06/2024-15:52:11-B17/06/2024-16:29:45",
  branch: "production",
  latestTag: "6.0",
  revCount: "10296",
  shortHash: "3c571edf26",
  longHash: "3c571edf265a79eef2a049096c95335272fc7a1e",
  dateCommit: "17/06/2024-15:52:11",
  dateBuild: "17/06/2024-16:29:45",
  buildType: "Ansible",
  } ;
