<dx-popup
  [showTitle]="true"
  [title]="'PERIODE DES PRIX'"
  [(visible)]="displayDialog"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeDialog()"
>
  <dx-toolbar class="yo-toolbar">
    <dxi-item location="before">
      <div class="d-flex flex-row align-items-center">
        <yo-button
          class="mg-r-10"
          iconClass="fa fa-plus"
          label="CRÉER"
          tooltip="Créer une période de prix"
          [disabled]="isDisabledDeleteBtn()"
          (onClick)="openDialogSavePeriodPrice()">
        </yo-button>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div id="after-toolbar" class="custom-toolbar-element">
        <yo-button
          iconClass="fa fa-trash"
          buttonClass="cta-inner-delete"
          (onClick)="deletePricePeriods()"
          [disabled]="isDisabledDeleteBtn()"
          tooltip="Supprimer les propositions sélectionnées">
        </yo-button>
      </div>
    </dxi-item>
  </dx-toolbar>

  <dx-data-grid
    id="gridContainer"
    [dataSource]="allPricePeriod"
    [width]="'auto'"
    [height]="'auto'"
    [showBorders]="true"
    [showRowLines]="true"
    [allowColumnResizing]="true"
    columnResizingMode="widget"
    [rowAlternationEnabled]="true"
    (onCellPrepared)="cellPrepared($event)"
    keyExpr="id"
    #grid>
    <dxo-selection [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode"
                   mode="multiple"></dxo-selection>
    <dxi-column dataField="id" caption="Actions" [width]="100"
                [alignment]="'left'"
                [allowFiltering]="false" cellTemplate="actionsCellTemplate"></dxi-column>
    <dxi-column dataField="dateEffective" caption="A partir du"
                [allowFiltering]="false" dataType="date"></dxi-column>
    <dxi-column dataField="libelle" caption="Libellé"
                [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="prix" caption="Prix d'achat en UF"
                [alignment]="'left'"
                cellTemplate="prixCellTemplate"></dxi-column>
    <dxi-column dataField="referenceArticle" caption="Réf. art. fourn."
                title="Référence article chez le fournisseur"></dxi-column>
    <dxi-column dataField="uniteDeFacturation" caption="UF (Unité de Facturation)"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [allowSorting]="false"
                cellTemplate="ufCellTemplate"></dxi-column>
    <dxi-column dataField="uniteDeCommande" caption="UC (Unité de Commande)"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [allowSorting]="false"
                cellTemplate="ucCellTemplate"></dxi-column>
    <dxi-column dataField="uniteDeStockage" caption="US (Unité de Stockage)" cellTemplate="usCellTemplate"
                [allowFiltering]="false"
                [allowGrouping]="false"
                [allowSorting]="false"
                [width]="150"></dxi-column>
    <dxi-column dataField="actif" caption="Actif" [width]="100"></dxi-column>

    <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
      <yo-cell-button (yoNavigation)="openDialogSavePeriodPrice(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoSmall]="true"
                      yoTextAlign="center"
                      [yoIconClass]="canEdit() ? 'fa fa-edit' : 'fas fa-eye'"
                      [yoTooltip]="canEdit() ? 'Modifier la période de prix' : 'Voir la période prix'">
      </yo-cell-button>
    </div>

    <div *dxTemplate="let cell of 'prixCellTemplate'">
      {{cell.row.data.groupeAchatCaList[0].prixUF.value  | currency:'EUR':'symbol':'1.4-4'}}
    </div>
    <div *dxTemplate="let cell of 'ufCellTemplate'">
      1 {{cell.row.data.uniteDeFacturation.libelle}} = {{cell.row.data.ratioUniteFacturationUniteBase}} KG
    </div>
    <div *dxTemplate="let cell of 'ucCellTemplate'">
      1 {{cell.row.data.uniteDeCommande.libelle}} = {{cell.row.data.ratioUniteCommandeUniteBase}} KG
    </div>
    <div *dxTemplate="let cell of 'usCellTemplate'">
      1 {{cell.row.data.uniteDeStockage.libelle}} = {{cell.row.data.ratioUniteStockageUniteBase}} KG
    </div>

    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[10, 20]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} période(s) de prix">
    </dxo-pager>
  </dx-data-grid>
</dx-popup>

<yo-dialog-save-period-price></yo-dialog-save-period-price>
