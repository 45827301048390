import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {UtilsService} from '../../utils/utils.service';
import {Observable, Subject} from 'rxjs';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {PlanProductionDTO} from '../../dtos/plan-production-dto';
import {FormGroup} from '@angular/forms';
import * as moment from 'moment';
import {FICHE_TECHNIQUE_PRINT_MODE} from "../../../gestion-production/pp/plans-de-production/print-settings/print-settings.component";
import {HttpService} from "../technique/http.service";
import {BulkDetailSupplier} from "../../../gestion-production/pp/plans-de-production/grid-details-pp/bulk-edition-details-dialog/bulk-edition-details-dialog.component";
import {VUE_SORTIES_POUR_PRODUCTION} from "../../constants";
import {FilterPrintSortiesProductionDTO} from "../../dtos/gestion-production/filter-print-sorties-production-dto";

export const URL_FETCH_ALL_PRODUCTION_PLAN = `dolrest/gestion-production/planProduction/all`;
export const URL_GET_ERREURS = `dolrest/gestion-production/planProductionDetail/erreurs`;
export const URL_GET_PRINT_SETTINGS = `dolrest/gestion-production/planProduction/printSettings`;
export const URL_GET_PRINT_PDF_RECAP_FAB = `dolrest/gestion-production/print/pdf/recapitulatif-fabrication`;
export const URL_PRINT_PDF_RECETTES = `dolrest/gestion-production/print/pdf/recettes`;
export const URL_POST_PRINT_PDF_SORTIES_STOCKS = `dolrest/gestion-production/print/pdf/sorties-stocks`;
export const URL_FETCH_PARENTS_DISH = `dolrest/gestion-production/planProduction/fetchParentsByDish/`
export const URL_PRINT_SORTIES_PRODUCTION = `dolrest/gestion-production/print/pdf/sorties-production`;

export const URL_POST_APPLY_MASSIVE_TREATMENT = `dolrest/gestion-production/applyMassiveTreatment/`;

export const URL_DELETE_PLANS_PRODS = `dolrest/gestion-production/deletePlansProductions`;
export const URL_GET_PLAN_PRODUCTION_BY_ID_AND_DATES = `dolrest/gestion-production/planProduction/fetchTeamsByWorkshops`;
export const URL_GET_EQUIPE = `dolrest/gestion-production/fetchUniteDeProductionEquipe`;
export const URL_GET_PLAN_PROD_DETAILS_FOR_FILTERS_SETTINGS_PRINT = `dolrest/gestion-production/fetchDishesForSettingsTechnicalRecipe`;
export const URL_START_PLAN_PRODUCTION = `dolrest/gestion-production/initialize-plan-production/`;
export const URL_FILTERS_PLAN_PRODUCTION = `dolrest/gestion-production/filters-plan-production`;
export const URL_SAVE_FILTERS = `dolrest/gestion-production/planProduction/saveFilters/`;
export const URL_FIND_PLAN_PRODUCTION_BY_ID = `dolrest/gestion-production/planProduction/findById`;
export const URL_GENERATE_PLAN_PRODUCTION = `dolrest/gestion-production/planProduction/generate/`;
export const URL_GENERATE_SORTIES_PLAN_PRODUCTION = 'dolrest/gestion-production/planProduction/generate-outputs/';
export const URL_MODIFY_REAL_QUANTITIES_US_SORTIES = 'dolrest/gestion-production/planProduction/readjust-real-quantities-outputs/';
export const URL_FIND_SORTIES_OF_PLAN_PRODUCTION = 'dolrest/gestion-production/planProduction/sorties';

export type TYPE_JOUR = 'jourFab' | 'jourConso';

@Injectable({
  providedIn: 'root'
})


export class ProductionService {

  protected subjectStartPlanProd = new Subject<any>();
  startPlanProd$ = this.subjectStartPlanProd.asObservable();

  private subjectRefreshItems = new Subject<PlanProductionDTO>();
  refreshItems$ = this.subjectRefreshItems.asObservable();

  private subjectOpenMassiveTreatmentDialog = new Subject<any>();
  openMassiveTreatmentDialog$ = this.subjectOpenMassiveTreatmentDialog.asObservable();

  private subjectmassiveTreatment = new Subject<any>();
  massiveTreatment$ = this.subjectmassiveTreatment.asObservable();

  private subSearchPlanProduction = new Subject();
  searchPlanProduction$ = this.subSearchPlanProduction.asObservable();

  private subOpenPrintSortiesProductionSettings = new Subject<any>();
  openPrintSortiesProductionSettings$ = this.subOpenPrintSortiesProductionSettings.asObservable();

  constructor(private http: HttpClient,
              private httpSvc: HttpService,
              private utils: UtilsService) {

  }

  searchPlansProduction = () => {
    return this.httpSvc.get(URL_FETCH_ALL_PRODUCTION_PLAN);
  };

  announceRefreshItems = (planProduction: PlanProductionDTO) => {
    this.subjectRefreshItems.next(planProduction);
  }

  announceOpenMassiveTreatmentDialog = (args: any) =>  {
    this.subjectOpenMassiveTreatmentDialog.next(args);
  }

  announceApplyMassiveTreatment = (data: BulkDetailSupplier) => {
    this.subjectmassiveTreatment.next(data);
  }

  announceOpenPrintSortiesProductionSettings = (planProduction: PlanProductionDTO, selectedVue: VUE_SORTIES_POUR_PRODUCTION) => {
    this.subOpenPrintSortiesProductionSettings.next({planProduction, selectedVue});
  }

  findById = (id: number, filters?: any): Observable<ResponseWrapper<any>> => {
    this.subSearchPlanProduction.next();

    let url = `${URL_FIND_PLAN_PRODUCTION_BY_ID}/${id}`;
    if (filters) {
      const args = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
      url += `?${args}`;
    }
    return this.httpSvc.get(url);
  }

  findSorties = (idPlanProduction: number, filters?: any): Observable<ResponseWrapper<any>> => {
    let url = `${URL_FIND_SORTIES_OF_PLAN_PRODUCTION}/${idPlanProduction}`;
    if (filters) {
      const args = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
      url += `?${args}`;
    }
    return this.httpSvc.get(url);
  }

  fetchParentsOfDish = (idPlanProduction: number, idSubDish: number, idDetailSubDishParent: number) => {
    return this.httpSvc.get(`${URL_FETCH_PARENTS_DISH}${idPlanProduction}/${idSubDish}/${idDetailSubDishParent}`);
  }


  getErreurs = (idPlanProdDetail: number) => {
    return this.http.get(URL_GET_ERREURS, {
      params: new HttpParams().set('idPlanProdDetail', idPlanProdDetail + '')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  };

  getPrintSettings = (idPlanProd: number): Observable<ResponseWrapper<any>> => {
    return this.http.get(URL_GET_PRINT_SETTINGS, {
      params: new HttpParams()
        .set('idPlanProd', idPlanProd + '')

    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  getByIdAndDatesPlanProduction = (idPlanProd: number, dateList: number[], jourConso: boolean): Observable<ResponseWrapper<any>> => {
    let theDateList = dateList.map(date => this.utils.convertYYYYMMdd(date)).join(',');
    return this.http.get(URL_GET_PLAN_PRODUCTION_BY_ID_AND_DATES, {
      params: new HttpParams()
        .set('idPlanProd', idPlanProd + '')
        .set('dateList', theDateList + '')
        .set('jourConso', jourConso + '')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };


  /**
   * Impression des sorties de stock au format pdf
   * @param planProd
   * @param dateFab
   */
  printSortiesStockPDF = (idPlanProd: number, idZoneDeProduction: number, idZoneDeStockage: number, dateFab: Date) => {
    const fd = new FormData();
    fd.set('idPlanDeProduction', idPlanProd + '');
    fd.set('idZoneDeProduction', idZoneDeProduction + '');
    fd.set('idZoneDeStockage', idZoneDeStockage + '');
    fd.set('dateFabrication', this.utils.getYYYYMMDD(moment(dateFab)));

    return this.http.post(URL_POST_PRINT_PDF_SORTIES_STOCKS, fd, {
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };

  getPlanProductionDetailsForFiltersSettingsPrint = (planProd: PlanProductionDTO, form: FormGroup, datesFab: number[], datesConso: number[]) => {
    const typeJour: TYPE_JOUR = form.controls['typeJour'].value;
    let selectedJours: any[] = (typeJour === 'jourFab') ? datesFab : datesConso;
    const idsTeams = form.controls['selectedEquipes'].value ? form.controls['selectedEquipes'].value.map(team => team.id).join(',') : [];
    selectedJours = selectedJours ? selectedJours.map(date => this.utils.convertYYYYMMdd(date)) : [];
    const params = `?idPlanProduction=${planProd.id}&useJourConso=${typeJour !== 'jourFab'}&selectedJours=${selectedJours}&selectedIdsEquipes=${idsTeams}`;
    return this.httpSvc.get(`${URL_GET_PLAN_PROD_DETAILS_FOR_FILTERS_SETTINGS_PRINT}${params}`);
  };

  /**
   * Impression des fiches techniques liées au pla de production planProd
   */
  printFichesTechniques = (idPlanProd: number, datesManufacture: string[], consumptionDates: string[], idsTeams: number[],
                           idsProductsToFilter: number[], form: FormGroup,
                           groupByPlc: boolean, groupByTeam: boolean, groupByMeal: boolean, groupByConsumptionDate: boolean,
                           printMode: FICHE_TECHNIQUE_PRINT_MODE,
                           printModeOrientation: FICHE_TECHNIQUE_PRINT_MODE) => {
    const params = `?idPlanProduction=${idPlanProd}&datesManufacture=${datesManufacture}&consumptionDates=${consumptionDates}&idsTeams=${idsTeams}&idsDishesToMake=${idsProductsToFilter}
                    &printMode=${printMode}&printModeOrientation=${printModeOrientation}&nbDeclinaisonsPerTableAllowed=10
                    &groupByPlc=${groupByPlc}&groupByTeam=${groupByTeam}&groupByMeal=${groupByMeal}&groupByConsumptionDate=${groupByConsumptionDate}`;
    return this.http.get(`${URL_PRINT_PDF_RECETTES}${params}`, {
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )
  };

  printRecapFabPDF = (idPlanProd: number, datesManufacture: string[], consumptionDates: string[], idsTeams: number[],
                      idsProductsToFilter: number[], form: FormGroup,
                      groupByPlc: boolean, groupByTeam: boolean, groupByMeal: boolean, groupByConsumptionDate: boolean,
                      printMode: FICHE_TECHNIQUE_PRINT_MODE,
                      printModeOrientation: FICHE_TECHNIQUE_PRINT_MODE) => {
    const params = `?idPlanProduction=${idPlanProd}&datesManufacture=${datesManufacture}&consumptionDates=${consumptionDates}&idsTeams=${idsTeams}&idsDishesToMake=${idsProductsToFilter}
                    &printMode=${printMode}&printModeOrientation=${printModeOrientation}&nbDeclinaisonsPerTableAllowed=10
                    &groupByPlc=${groupByPlc}&groupByTeam=${groupByTeam}&groupByMeal=${groupByMeal}&groupByConsumptionDate=${groupByConsumptionDate}`;

    return this.http.get(`${URL_GET_PRINT_PDF_RECAP_FAB}${params}`, {
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  };

  printSortiesProduction = (idPlanProd: number, viewSelected: string, useViewByAtelier: boolean, filters: FilterPrintSortiesProductionDTO) => {
    let params: string = `?idPlanProduction=${idPlanProd}&viewSelected=${viewSelected}&useViewByAtelier=${useViewByAtelier}`;

    let url = `${URL_PRINT_SORTIES_PRODUCTION}` + params;
    if (filters) {
      const args = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
      url += `&${args}`;
    }

    return this.http.get(url, {
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    );
  }

  /**
   * Supprimer des plans de production
   * @param pp
   */
  deletePlansProds = (plansProductions: number[]) => {
    const ids: string = plansProductions ? plansProductions.join(',') : '';
    return this.httpSvc.delete(`${URL_DELETE_PLANS_PRODS}?idsPlanProduction=${ids}`);
  };

  fetchUdpTeams = (idPlanProduction: number) => {
    return this.httpSvc.get(`${URL_GET_EQUIPE}?idPlanProduction=${idPlanProduction}`);
  };

  announceStartPlanProduction = () => {
    this.subjectStartPlanProd.next();
  }

  startPlanProduction: (idSite, label) => Observable<any> = (idSite, label) => {
    return this.httpSvc.post(`${URL_START_PLAN_PRODUCTION}${idSite || -1}?label=${encodeURIComponent(label)}`, null, null);
  }

  getFiltersPlanProduction: () => Observable<any> = () => this.httpSvc.get(URL_FILTERS_PLAN_PRODUCTION);

  saveFilters = (idPlanProduction: number, filters: any): Observable<any> => this.httpSvc.post(`${URL_SAVE_FILTERS}${idPlanProduction}`, filters);

  generateRowsPlanProduction = (idPlanProduction: number, filters: any, excludedDishesWithoutEffectif: boolean = false): Observable<any> => {
    const params: HttpParams = new HttpParams().set('excludedDishesWithoutEffectif', excludedDishesWithoutEffectif);
    return this.httpSvc.post(`${URL_GENERATE_PLAN_PRODUCTION}${idPlanProduction}`, filters, params);
  }

  applyMassiveTreatment = (id: number, tasks: any): Observable<any> => this.httpSvc.post(`${URL_POST_APPLY_MASSIVE_TREATMENT}${id}`, tasks);

  generateSortiesPlanProduction = (idPlanProduction: number, forceGenerationListeBesoins?: boolean): Observable<any> => {
    if(forceGenerationListeBesoins) {
      return this.httpSvc.post(`${URL_GENERATE_SORTIES_PLAN_PRODUCTION}${idPlanProduction}`, null,
        new HttpParams().set('forceGenerationListeBesoins', forceGenerationListeBesoins));
    }
    return this.httpSvc.post(`${URL_GENERATE_SORTIES_PLAN_PRODUCTION}${idPlanProduction}`, null);
  }

  updateRealQuantitiesUs = (idPlanProduction: number, viewSelected: string, sortiesUpdated: any[], aroundQteReelleUs: boolean): Observable<any> => {
    const formData = new FormData();
    formData.append('args', JSON.stringify({ sortiesUpdated, viewSelected, aroundQteReelleUs }));

    return this.httpSvc.post(`${URL_MODIFY_REAL_QUANTITIES_US_SORTIES}${idPlanProduction}`, formData);
  }

}
