import {Component, OnDestroy, OnInit} from '@angular/core';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ProduitDTO} from '../../../../core/dtos/produit-dto';
import {ProduitDeclinaisonDTO} from '../../../../core/dtos/produit-declinaison-dto';
import {ProduitDeclinaisonService} from '../../../../core/services/entities/produit-declinaison.service';
import {FormGroup} from '@angular/forms';
import {FormFieldBaseSupplier} from '../../../../core/suppliers/form-fieldbase-supplier';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {Observable, Subscription} from 'rxjs';
import {GROUP_POSITION, GroupFormFields} from '../../../../core/suppliers/group-formfields';
import {ContrainteModel} from '../../../../core/models/contrainte-model';
import {ProduitDeclinaisonContrainteAlimDTO} from '../../../../core/dtos/produit-declinaison-contrainte-alim-dto';
import {cloneDeep as _cloneDeep} from 'lodash';
import {tap} from 'rxjs/operators';
import {switchMap} from 'rxjs/internal/operators';
import {of} from 'rxjs/internal/observable/of';
import {DomSanitizer} from '@angular/platform-browser';
import {MenuItem} from "primeng/api";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {TextMessageService} from "../../../../shared/ui/text-messages/textMessage.service";

@Component({
  selector: 'yo-declinaison-produit-dialog-edit',
  templateUrl: './declinaison-produit-dialog-edit.component.html',
  styleUrls: ['./declinaison-produit-dialog-edit.component.scss']
})
export class DeclinaisonProduitDialogEditComponent implements OnInit, OnDestroy {

  items: MenuItem[] = [];
  produitDeclinaison: ProduitDeclinaisonDTO;
  produit: ProduitDTO;
  fields: FormFieldBaseSupplier<any>[];
  displayDialog = false;

  groups: GroupFormFields[] = [];
  form = new FormGroup({});
  formInfos = new FormGroup({});
  formFacturation = new FormGroup({});
  formFabrication = new FormGroup({});
  formApprovisionnement = new FormGroup({});
  formSortie = new FormGroup({});


  subOpenDialog: Subscription;
  subNewUniteTechnique:Subscription;

  deleteReponse$: Observable<boolean> = new Observable<boolean>();
  subscriptionDeletedId: Subscription;

  pathFile: string = HELP_FOLDERS.PRODUITS + '/edition-produits-declinaisons';

  constructor(
    private gfs: GenericFormService,
    private routeMapSvc: RoutemapService,
    private produitDeclinaisonSvc: ProduitDeclinaisonService,
    private textMessageService: TextMessageService,
    public gds: GenericDatagridService,
    public domSanitizer: DomSanitizer,
    public utils: UtilsService,
    private toastSvc: ToastService) {
  }

  ngOnInit() {
    // ouverture du dialog
    this.openDialogSubscription();
    // abonnement à la suppression d'une déclinaison
    this.deletedIdSubscription();
    // affectation de la nouvelle unite techniue ne cas de changement
    this.subcriptionNewUniteTechnique();

    this.initMenuOption();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionDeletedId);
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subNewUniteTechnique);
  }

  subcriptionNewUniteTechnique = () => {
    this.subNewUniteTechnique = this.produitDeclinaisonSvc.newUniteTechnique$.subscribe( pd => {
      this.produitDeclinaison.uniteDeMesure = pd.uniteDeMesure;
      this.produitDeclinaison.ratioUniteTechnique = pd.ratioUniteTechnique;
      this.initForm(this.produit, this.produitDeclinaison);
    });
  };

  initMenuOption = () => {
    this.items =[{
      label: 'Changer l\'unité technique',
      icon: 'fas fa-random',
      command: () => {
        this.produitDeclinaisonSvc.announceOpenDialogChangeUniteTechnique(this.produitDeclinaison);
      }
    }
    ];
  };

  initFormDenree = (fields: FormFieldBaseSupplier<any>[], formInfos: FormGroup, formFacturation: FormGroup, formFabrication: FormGroup, formApprovisionnement: FormGroup, formSortie: FormGroup) => {

    const fieldsInfosKey = ['site', 'libelle', 'libellelong', 'declinaison', 'code', 'actif'];
    const fieldsInfos = this.gfs.getFieldsByKey(fieldsInfosKey, this.fields);
    formInfos = this.gfs.toFormGroup(fieldsInfos);

    //const fieldsFacturationKey = ['codecomptable', 'prixAchat', 'prixDeVente', 'taxe'];
    const fieldsFacturationKey = ['codecomptable', 'prixDeVente', 'taxe'];
    const fieldsFacturation = this.gfs.getFieldsByKey(fieldsFacturationKey, this.fields);
    formFacturation = this.gfs.toFormGroup(fieldsFacturation);

    const fieldsFabricationKey = ['uniteDeMesure', 'ratioUniteTechnique', 'inverseRatioTechnique', 'ratioVolume'];
    const fieldsFabrication = this.gfs.getFieldsByKey(fieldsFabricationKey, this.fields);
    formFabrication = this.gfs.toFormGroup(fieldsFabrication);

    const fieldsApprovisionnementKey = ['dlc', 'zoneDeStockage', 'tacheApprovisionnement'];
    const fieldsApprovisionnement = this.gfs.getFieldsByKey(fieldsApprovisionnementKey, this.fields);
    formApprovisionnement = this.gfs.toFormGroup(fieldsApprovisionnement);

    const fieldsSortieKey = ['tacheSortie'];
    const fieldsSortie = this.gfs.getFieldsByKey(fieldsSortieKey, this.fields);
    formSortie = this.gfs.toFormGroup(fieldsSortie);

    const groupFormFields = [
      {
        titlePanel: 'Informations',
        fields: fieldsInfos,
        form: formInfos,
        titleIcon: 'fas fa-info-circle',
        position: GROUP_POSITION.COL1,
        colsOrganization: {
          col1: fieldsInfosKey,
        }
      },
      {
        titlePanel: 'Facturation',
        fields: fieldsFacturation,
        form: formFacturation,
        titleIcon: '',
        position: GROUP_POSITION.COL2,
        colsOrganization: {
          col2: fieldsFacturationKey,
        }
      },
      {
        titlePanel: 'Approvisionnement',
        fields: fieldsApprovisionnement,
        form: formApprovisionnement,
        titleIcon: '',
        position: GROUP_POSITION.COL1,
        colsOrganization: {
          col1: fieldsApprovisionnementKey,
        }
      },
      {
        titlePanel: 'Sortie',
        fields: fieldsSortie,
        form: formSortie,
        titleIcon: '',
        position: GROUP_POSITION.COL2,
        colsOrganization: {
          col2: fieldsSortieKey,
        }
      },
      {
        titlePanel: 'Fabrication',
        fields: fieldsFabrication,
        form: formFabrication,
        titleIcon: '',
        position: GROUP_POSITION.COL1_AND_COL2,
        colsOrganization: {
          col1: ['uniteDeMesure', 'ratioVolume'],
          col2: ['ratioUniteTechnique', 'inverseRatioTechnique']
        }
      },

    ];

    return {groupFormFields, formInfos, formFacturation, formFabrication, formApprovisionnement, formSortie};
  };

  initFormPlat = (fields: FormFieldBaseSupplier<any>[], formInfos: FormGroup, formFacturation: FormGroup, formFabrication: FormGroup) => {


    const fieldsInfosKey = ['site', 'libelle', 'declinaison', 'libellelong', 'code', 'actif'];
    const fieldsInfos = this.gfs.getFieldsByKey(fieldsInfosKey, this.fields);
    formInfos = this.gfs.toFormGroup(fieldsInfos);

    //const fieldsFacturationKey = ['codecomptable', 'prixAchat', 'prixDeVente', 'taxe'];
    const fieldsFacturationKey = ['codecomptable', 'prixDeVente', 'taxe'];
    const fieldsFacturation = this.gfs.getFieldsByKey(fieldsFacturationKey, this.fields);
    formFacturation = this.gfs.toFormGroup(fieldsFacturation);


    const fieldsFabricationKey = ['uniteDeMesure', 'ratioUniteTechnique', 'inverseRatioTechnique', 'ratioVolume', 'dlc', 'regimes', 'tacheFabrication', 'modeOperatoire'];
    const fieldsFabrication = this.gfs.getFieldsByKey(fieldsFabricationKey, this.fields);
    formFabrication = this.gfs.toFormGroup(fieldsFabrication);

    const groupFormFields = [
      {
        titlePanel: 'Informations',
        fields: fieldsInfos,
        form: formInfos,
        titleIcon: 'fas fa-info-circle',
        position: GROUP_POSITION.COL1,
        colsOrganization: {
          col1: fieldsInfosKey,
        }
      },
      {
        titlePanel: 'Facturation',
        fields: fieldsFacturation,
        form: formFacturation,
        titleIcon: '',
        position: GROUP_POSITION.COL2,
        colsOrganization: {
          col2: fieldsFacturationKey,
        }
      },
      {
        titlePanel: 'Fabrication',
        fields: fieldsFabrication,
        form: formFabrication,
        titleIcon: '',
        position: GROUP_POSITION.COL1_AND_COL2,
        colsOrganization: {
          col1: ['uniteDeMesure', 'ratioUniteTechnique', 'inverseRatioTechnique', 'ratioVolume', 'dlc'],
          col2: ['regimes', 'tacheFabrication', 'modeOperatoire'],
        }
      },
    ];

    return {groupFormFields, formInfos, formFacturation, formFabrication};

  };

  /**
   * Initialiser les formulaires
   */
  initForm = (produit: ProduitDTO, produitDeclinaison: ProduitDeclinaisonDTO) => {

    this.produitDeclinaisonSvc.prepareFields(produit, produitDeclinaison).subscribe(response => {

      this.produitDeclinaison = response.produitDeclinaison;
      this.fields = response.fields;

      let formObjects = null;

      switch (this.produitDeclinaison.fabrique) {

        // PLAT
        case true:

          formObjects = this.initFormPlat(this.fields, this.formInfos, this.formFacturation, this.formFabrication);
          this.formInfos = formObjects.formInfos;
          this.formFacturation = formObjects.formFacturation;
          this.formFabrication = formObjects.formFabrication;
          this.form = new FormGroup({
            formInfos: this.formInfos,
            formFacturation: this.formFacturation,
            formFabrication: this.formFabrication
          });
          break;

        // DENREE
        case false:
          formObjects = this.initFormDenree(this.fields, this.formInfos, this.formFacturation, this.formFabrication, this.formApprovisionnement, this.formSortie);
          this.formInfos = formObjects.formInfos;
          this.formFacturation = formObjects.formFacturation;
          this.formFabrication = formObjects.formFabrication;
          this.formApprovisionnement = formObjects.formApprovisionnement;
          this.formSortie = formObjects.formSortie;
          this.form = new FormGroup({
            formInfos: this.formInfos,
            formFacturation: this.formFacturation,
            formFabrication: this.formFabrication,
            formApprovisionnement: this.formApprovisionnement,
            formSortie: this.formSortie
          });
          break;
      }
      this.groups = formObjects.groupFormFields;
    });
  };

  openDialogSubscription = () => {
    this.subOpenDialog = this.produitDeclinaisonSvc.openDialogProduitDeclinaison$
      .pipe(
        tap(data => this.displayDialog = true),
        switchMap(data => {
          this.produitDeclinaison = data.produitDeclinaison;
          this.produit = data.produit;
          this.initForm(this.produit, this.produitDeclinaison);
          return of(null);
        })
      )
      .subscribe();
  };

  deletedIdSubscription = () => {
    this.subscriptionDeletedId = this.gfs.deletedId$.subscribe((response) => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.closeDialog();
      }
    });
  };

  getTitle = (produitDeclinaison: ProduitDeclinaisonDTO) => {

    if (!this.utils.isNullOrEmpty(produitDeclinaison)) {

      if (produitDeclinaison.id === 0) {
        return `<i class="fas fa-plus mg-r-5"></i>Créer une déclinaison de ${produitDeclinaison.fabrique ? 'plat' : 'denrée'}`;
      } else {
        return `<i class="fas fa-edit mg-r-5" pTooltip="Modifier la déclinaison de produit"></i> ${produitDeclinaison.libelle.toUpperCase()}`;
      }

    }

    return '';
  };

  help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Paramétrage d'un produit/denrée`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Déclinaison d'un <b>produit/denrée</b>`;
    p1.lines = [
      `On retrouve ici des paramètres qui seront réutilisés en création de <b>plat</b> :`,
      `<ul>
<li>unité technique</li>
<li>ration entre unité technique et l’unité universelle le Kg</li>
</ul>`,
    ];

    dms.content = {
      intro: `Une <b>déclinaison</b> comporte les paramètres orientés « production ».`,
      paragraphes: [p1]
    };

    return dms;
  };


  openDeleteDialog = (produitDeclinaison: ProduitDeclinaisonDTO) => {
    const message = this.textMessageService.getProduitDeletionMessage(produitDeclinaison);
    this.gfs.openDeleteDialog(message,
      this.produitDeclinaisonSvc.getEntityName(),
      produitDeclinaison
    );
  };

  prepareSavePlat = (produitDeclinaison: ProduitDeclinaisonDTO, formInfos: FormGroup, formFacturation: FormGroup, formFabrication: FormGroup): ProduitDeclinaisonDTO => {

    // infos
    produitDeclinaison.site = formInfos.controls['site'].value;
    produitDeclinaison.libelle = formInfos.controls['libelle'].value;
    produitDeclinaison.libellelong = formInfos.controls['libellelong'].value;
    produitDeclinaison.code = formInfos.controls['code'].value;
    produitDeclinaison.actif = formInfos.controls['actif'].value;
    produitDeclinaison.declinaison = formInfos.controls['declinaison'].value;

    // facturation
    produitDeclinaison.codecomptable = formFacturation.controls['codecomptable'].value;
    produitDeclinaison.prixDeVente = formFacturation.controls['prixDeVente'].value;
    produitDeclinaison.taxe = formFacturation.controls['taxe'].value;

    // fabrication
    produitDeclinaison.uniteDeMesure = formFabrication.controls['uniteDeMesure'].value;
    produitDeclinaison.ratioUniteTechnique = formFabrication.controls['ratioUniteTechnique'].value;
    produitDeclinaison.ratioVolume = formFabrication.controls['ratioVolume'].value;
    produitDeclinaison.dlc = formFabrication.controls['dlc'].value;
    produitDeclinaison.produitDeclinaisonContrainteAlimList = this.prepareSaveRegimes(formFabrication);
    produitDeclinaison.modeOperatoire = formFabrication.controls['modeOperatoire'].value;
    const tacheFabrication = formFabrication.controls['tacheFabrication'].value;
    const ptFabrication = this.produitDeclinaisonSvc.initTacheOfProduitDeclinaison(produitDeclinaison, tacheFabrication);
    produitDeclinaison.produitDeclinaison__tacheList = [ptFabrication];

    //stock
    produitDeclinaison.zoneDeStockage = null;

    return produitDeclinaison;
  };

  prepareSaveDenree = (produitDeclinaison: ProduitDeclinaisonDTO, formInfos: FormGroup, formFacturation: FormGroup, formFabrication: FormGroup, formApprovisionnement: FormGroup, formSortie: FormGroup): ProduitDeclinaisonDTO => {


    // infos
    produitDeclinaison.site = formInfos.controls['site'].value;
    produitDeclinaison.libelle = formInfos.controls['libelle'].value;
    produitDeclinaison.libellelong = formInfos.controls['libellelong'].value;
    produitDeclinaison.code = formInfos.controls['code'].value;
    produitDeclinaison.actif = formInfos.controls['actif'].value;
    produitDeclinaison.declinaison = formInfos.controls['declinaison'].value;


    // facturation
    produitDeclinaison.codecomptable = formFacturation.controls['codecomptable'].value;
    produitDeclinaison.prixDeVente = formFacturation.controls['prixDeVente'].value;
    produitDeclinaison.taxe = formFacturation.controls['taxe'].value;

    // fabrication
    produitDeclinaison.uniteDeMesure = formFabrication.controls['uniteDeMesure'].value;
    produitDeclinaison.ratioUniteTechnique = formFabrication.controls['ratioUniteTechnique'].value;
    produitDeclinaison.ratioVolume = formFabrication.controls['ratioVolume'].value;


    // approvisionnement
    produitDeclinaison.dlc = formApprovisionnement.controls['dlc'].value;
    produitDeclinaison.zoneDeStockage = formApprovisionnement.controls['zoneDeStockage'].value;
    const tacheApprovisionnement = formApprovisionnement.controls['tacheApprovisionnement'].value;
    const pdtTacheApprovisionnement = this.produitDeclinaisonSvc.initTacheOfProduitDeclinaison(produitDeclinaison, tacheApprovisionnement);

    // sortie
    const tacheSortie = formSortie.controls['tacheSortie'].value;
    const pdtTacheSortie = this.produitDeclinaisonSvc.initTacheOfProduitDeclinaison(produitDeclinaison, tacheSortie);


    produitDeclinaison.produitDeclinaison__tacheList = [pdtTacheSortie, pdtTacheApprovisionnement];

    return produitDeclinaison;
  };

  prepareSaveRegimes = (formFabrication: FormGroup) => {

    const produitDeclinaisonContrainteAlimList: ProduitDeclinaisonContrainteAlimDTO[] = [];

    const contraintes: ContrainteModel[] = _cloneDeep(formFabrication.controls['regimes'].value);

    if (!this.utils.isNullOrEmpty(contraintes)) {
      this.produitDeclinaison.produitDeclinaisonContrainteAlimList = [];
      for (let contrainte of contraintes) {
        let contrainteDto: ProduitDeclinaisonContrainteAlimDTO = new ProduitDeclinaisonContrainteAlimDTO();
        contrainteDto.id = 0;
        contrainteDto.produitDeclinaisonLibelle = this.produitDeclinaison.libelle;
        contrainteDto.produitDeclinaisonId = this.produitDeclinaison.id;
        contrainteDto.textureId = contrainte.texture.id;
        contrainteDto.regimeId = contrainte.regime.id;
        contrainteDto.produitDeclinaisonId = 0;
        produitDeclinaisonContrainteAlimList.push(contrainteDto);
      }
    }

    return produitDeclinaisonContrainteAlimList;

  };


  /**
   * Enregistrer un produit déclinaison (plat ou denree)
   */
  saveProduitDeclinaison = () => {

    //Afficher les erreurs de validation
    this.gfs.validateAllFormFields(this.form);

    //On enregistre que si le formulaire est valide
    if (this.form.valid) {

      switch (this.produit.typeProduit.fabrique) {

        // PLAT
        case true:
          this.produitDeclinaison = this.prepareSavePlat(this.produitDeclinaison, this.formInfos, this.formFacturation, this.formFabrication);
          break;

        // DENREE
        case false:
          this.produitDeclinaison = this.prepareSaveDenree(this.produitDeclinaison, this.formInfos, this.formFacturation, this.formFabrication, this.formApprovisionnement, this.formSortie);
          break;

      }


      this.produitDeclinaisonSvc.saveProduitDeclinaison(this.produitDeclinaison).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.produitDeclinaisonSvc.announceSavedDTO(response);
          this.produitDeclinaison = response.one;
          this.initForm(this.produit, this.produitDeclinaison);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `L'enregistrement de la déclinaison de produit ${this.produitDeclinaison.libelle.toUpperCase()} ${this.produitDeclinaison.declinaison.libelle.toUpperCase()} a été réalisée avec succès`);
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, response.resultMessage);
        }

        this.closeDialog();
        this.routeMapSvc.goToSecondaryRoute(['gestionproduits', 'produit', this.produit.typeProduit.fabrique, this.produit.id, 'produit-declinaisons']);
      });
    }
  };

  canDelete = (produitDeclinaison: ProduitDeclinaisonDTO) => {
    let response: boolean = this.gds.canDelete(produitDeclinaison);
    return response;
  };

  closeDialog = () => {
    this.displayDialog = false;
  };

}


export class DialogEditDeclinaisonProduitSupplier {
  title: string;
  logo: string;
  entityName: string = 'produitDeclinaison';
  produit: ProduitDTO;
  produitDeclinaison: ProduitDeclinaisonDTO;
  produitDeclnaisonService: ProduitDeclinaisonService;

}
